.header {
  background-color: #000;
  padding: 16px 0;
  margin-bottom: 16px;
}

.header-wrapper {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.programme-title {
  font-size: 1.25rem;
  font-weight: 700;
}

.loading-message {
  font-size: .65rem;
  color: #7c7979;
}

.row-header {
  font-weight: 700;
}