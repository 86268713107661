/* Programme body CSS values for Programme Viewer App */
.body-wrapper {
  flex-direction: column;
  margin-bottom: 16px !important;
  gap: 4px;
}

.row-header {
  font-weight: 700;
}

.row-content {
  flex-grow: 1;
  flex-basis: 0; /* should be min-content but having to use 0 to support old iPhone */
  flex-direction: row;
  display: flex;
  gap: 8px;
}

a:link, a:visited {
  color: #af272f;
}

@media screen and (min-width: 320px) {
  .body-wrapper {
    flex-direction: row;
    gap: 16px;
    margin-bottom: 16px;
  }

  .loading-message {
    font-size: .85rem;
  }

  .row-header {
    flex-basis: 6em;
  }
}

@media screen and (min-width: 768px) {
  .header {
    padding: 32px 0;
    margin-bottom: 32px;
  }

  .programme-title {
    font-size: 2rem;
  }

  .row-header {
    flex-basis: 9em;
  }

  .body-wrapper {
    font-size: 1.25rem;
  }
}